html,
body,
#root {
  width: 100%;
  touch-action: manipulation;
  padding: 0;
  margin: 0;
}



@font-face {
  font-family: "Chakra Petch";
  src: url("./font/ChakraPetch-Medium.ttf");
}

@font-face {
  font-family: "Chakra Petch-Bold";
  src: url("./font/ChakraPetch-Bold.ttf");
}

@font-face {
  font-family: "NanumMyeongjo-Regular";
  src: url("./font/NanumMyeongjo-Regular.ttf");
}

@font-face {
  font-family: "NanumMyeongjo-Bold";
  src: url("./font/NanumMyeongjo-Bold.ttf");
}

@font-face {
  font-family: "NanumMyeongjo-ExtraBold";
  src: url("./font/NanumMyeongjo-ExtraBold.ttf");
}

p,
title,
h1,
h2,
h3,
h4,
h5,
h6,
a,
em,
strong,
ul,
li,
ol,
dl,
dt,
dd,
video,
audio,
table,
th,
td,
form,
button,
textarea,
select,
option,
span,
header,
footer,
nav,
article,
div {
  font-family: Chakra Petch !important;
}